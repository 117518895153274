/**
 * The different homepage tabs that the app currently has available
 */
export enum TabEnum {
  Create = 'create',
  Edit = 'edit',
  Table = 'table',
  Map = 'map',
  CreateVilla = 'create-villa',
  Search = 'search',
  CreatePublicRecord = 'create-public-record',
}

/**
 * An array of the different tabs that the app currently has available (TabEnum)
 */
export const TabArray = Object.values(TabEnum);

/**
 * Provides a legible name and a description for all available tabs (TabEnum)
 */
export const TabDetails = {
  [TabEnum.Create]: {
    name: 'Create',
    desc: 'displays the create listing screen'
  },
  [TabEnum.Edit]: {
    name: 'Edit',
    desc: 'displays the edit listing search'
  },
  [TabEnum.Table]: {
    name: 'Table',
    desc: 'displays a configurable listings table'
  },
  [TabEnum.Map]: {
    name: 'Map',
    desc: 'a pretty view with coordinates'
  },
  [TabEnum.CreateVilla]: {
    name: 'Create Villa',
    desc: 'for villa'
  },
  [TabEnum.Search]: {
    name: 'Search',
    desc: 'for simply searching listings'
  },
  [TabEnum.CreatePublicRecord]: {
    name: 'Create Public Record',
    desc: 'for creating a listing via public records'
  },
}
