import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppDialog, AppDialogConfig } from './_shared/app/dialog/app.dialog';
import { Router } from '@angular/router';
import { UserService } from './_service/user.service';
import { environment } from '../environments/environment';
import { UserModel } from './_model/member/user.model';
import { Location } from '@angular/common';
import { HomeArray, HomeDetails, HomeEnum } from './_enum/settings/home.enum';
import { handleChunkLoadError } from './_helper/util.helper';
import { EnvironmentEnum } from '../environments/environment.base';
import { AuthService } from './__auth/auth.service';
import { NotificationService } from './_service/notification.service';
import { PropertyService } from './_service/property.service';
import { ClearService } from './_service/clear.service';
import { SettingsService } from './_service/settings.service';
import { HomeModel } from './_model/settings/home.model';
import { ThemeModel } from './_model/settings/theme.model';
import { UrlsModel } from './_model/settings/urls.model';
import { MiscModel } from './_model/settings/misc.model';
import { ImageEditDialog, ImageEditDialogConfig } from './_shared/form/input/file/dialog/image-edit.dialog';
import { CompressionService } from './_service/compression.service';

@Component({
  selector: 'app-toolbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-toolbar *ngIf="user" [style.background-color]="theme.toolbar" [style.color]="theme.toolbarText" [class]="'toolbar' + (misc?.showOrangeToolbar ? '-orange' : '')">
      <!-- Logo -->
      <div class="toolbar-logo-wrapper">
        <div class="toolbar-logo-container">
          <app-logo></app-logo>
        </div>
      </div>
      <div *ngIf="misc?.showOrangeToolbar" class="toolbar-center">
        <div class="toolbar-orange-label">
          <span class="toolbar-center-label">{{misc?.orangeToolbarText}}</span>
        </div>
        <div *ngIf="misc?.orangeToolbarSubtext" class="toolbar-orange-subtext">
          <span class="toolbar-center-label">{{misc?.orangeToolbarSubtext}}</span>
        </div>
      </div>
      <!-- Toolbar Buttons -->
      <div class="toolbar-links">
        <!-- Dev Options -->
        <ng-container *ngIf="devMode">
          <div matRipple matRippleColor="rgba(255, 255, 255, 0.1)" class="toolbar-link" [matMenuTriggerFor]="devMenu">
            <div class="toolbar-icon-wrapper">
              <mat-icon class="toolbar-icon">developer_mode</mat-icon>
            </div>
          </div>
          <mat-menu #devMenu="matMenu">
            <div class="w-100 text-center">Dev Menu</div>
            <!-- Impersonate -->
            <div mat-menu-item *ngIf="realUser?.perms.is.Amp || user?.perms.is.Amp" (click)="$event.stopPropagation()">
              <mat-form-field *ngIf="!impersonating" class="w-100">
                <mat-label>Impersonate</mat-label>
                <input matInput #impersonateInput (keydown.enter)="impersonate(impersonateInput.value)" placeholder="key goes here, then Enter" />
              </mat-form-field>
              <button *ngIf="impersonating" mat-flat-button class="w-100 bg-orange" (click)="stopImpersonate()">Stop Impersonating</button>
            </div>
            <!-- Change home screen -->
            <div mat-menu-item>
              <mat-form-field class="w-50">
                <mat-label>Home Page</mat-label>
                <mat-select [value]="home?.homePage" (selectionChange)="setHomePage($event.value)">
                  <mat-option *ngFor="let home of homes" [value]="home">{{homeDetails[home].name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-50">
                <mat-label>Log Level</mat-label>
                <mat-select [value]="environment['logLevel']" (selectionChange)="setLogLevel($event.value)">
                  <mat-option [value]="3">All</mat-option>
                  <mat-option [value]="2">Warn</mat-option>
                  <mat-option [value]="1">Error</mat-option>
                  <mat-option [value]="0">None</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Theme -->
            <div class="w-100 text-center">Theme</div>
            <div mat-menu-item (click)="$event.stopPropagation()">
              <mat-form-field class="w-25">
                <mat-label>Toolbar</mat-label>
                <input matInput #toolbarInput [value]="theme?.toolbar" type="color"
                      (input)="changeTheme(toolbarInput.value, primaryInput.value, accentInput.value, warnInput.value)" />
              </mat-form-field>
              <mat-form-field class="w-25">
                <mat-label>Primary</mat-label>
                <input matInput #primaryInput [value]="theme?.primary" type="color"
                      (input)="changeTheme(toolbarInput.value, primaryInput.value, accentInput.value, warnInput.value)" />
              </mat-form-field>
              <mat-form-field class="w-25">
                <mat-label>Accent</mat-label>
                <input matInput #accentInput [value]="theme?.accent" type="color"
                      (input)="changeTheme(toolbarInput.value, primaryInput.value, accentInput.value, warnInput.value)" />
              </mat-form-field>
              <mat-form-field class="w-25">
                <mat-label>Warn</mat-label>
                <input matInput #warnInput [value]="theme?.warn" type="color"
                      (input)="changeTheme(toolbarInput.value, primaryInput.value, accentInput.value, warnInput.value)" />
              </mat-form-field>
            </div>
            <div mat-menu-item (click)="$event.stopPropagation()">
              <mat-form-field class="w-50">
                <mat-label>Light Background</mat-label>
                <input matInput #lightBGInput [value]="theme.lightBG" type="color"
                      (input)="changeBG(false, lightBGInput.value)" />
              </mat-form-field>
              <mat-form-field class="w-50">
              <mat-label>Dark Background</mat-label>
              <input matInput #darkBGInput [value]="theme.darkBG" type="color"
                    (input)="changeBG(true, darkBGInput.value)" />
              </mat-form-field>
            </div>
            <!-- Misc -->
            <div class="row-between align-items no-wrap">
              <button mat-menu-item (click)="autofill()"><mat-icon color="accent">fact_check</mat-icon>Autofill</button>
              <button mat-menu-item (click)="toggleConfirm()"><mat-icon [class]="environment.confirmAction ? 'orange' : ''">
                {{environment.confirmAction ? 'toggle_on' : 'toggle_off'}}</mat-icon>Confirms
              </button>
            </div>
            <div class="row-between align-items no-wrap">
              <button mat-menu-item class="w-50" (click)="unauthorize()"><mat-icon color="warn">remove_circle</mat-icon>Unauth</button>
              <button mat-menu-item class="w-50" (click)="clearCache()"><mat-icon color="warn">delete</mat-icon>Clear Mem</button>
            </div>
            <div class="row-between align-items no-wrap">
              <button mat-menu-item class="w-50" (click)="hideDevMenu()"><mat-icon color="warn">hide_source</mat-icon>Hide Menu</button>
            </div>
          </mat-menu>
        </ng-container>
        <!-- Training Mode -->
        <div *ngIf="prodMode && user.customerName === 'trreb'" matRipple matRippleColor="rgba(255, 255, 255, 0.1)"
              class="toolbar-link hide-mobile" (click)="toTraining()">
          <div class="toolbar-icon-wrapper">
            <mat-icon class="toolbar-icon">school</mat-icon>
          </div>
          <div class="toolbar-label">Training Mode</div>
        </div>
        <!-- Help Menu -->
        <div *ngIf="user.perms.is.Mls" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" class="toolbar-link" [matMenuTriggerFor]="helpMenu">
          <div class="toolbar-icon-wrapper">
            <mat-icon class="toolbar-icon">contact_support</mat-icon>
          </div>
        </div>
        <mat-menu #helpMenu="matMenu">
          <a [href]="urls?.support || supportUrl" target="_blank"><button mat-menu-item><mat-icon color="accent">bug_report</mat-icon>Report an Issue</button></a>
        </mat-menu>
        <!-- Start a Listing -->
        <div *ngIf="user.perms.Listing.Create && !creating" matRipple matRippleColor="rgba(255, 255, 255, 0.1)"
              class="toolbar-link" routerLink="listing/create">
          <div class="toolbar-icon-wrapper">
            <mat-icon class="toolbar-icon">create</mat-icon>
          </div>
          <div class="toolbar-label">New Listing</div>
        </div>
        <!-- Home -->
        <a matRipple matRippleColor="rgba(255, 255, 255, 0.1)"
              class="toolbar-link" routerLink="home">
          <div class="toolbar-icon-wrapper">
            <mat-icon class="toolbar-icon">home</mat-icon>
          </div>
          <div class="toolbar-label">Home</div>
        </a>
        <!-- Admin -->
        <a *ngIf="user.perms.is.Admin" matRipple matRippleColor="rgba(255, 255, 255, 0.1)"
              class="toolbar-link" routerLink="admin">
          <div class="toolbar-icon-wrapper">
            <mat-icon class="toolbar-icon">admin_panel_settings</mat-icon>
          </div>
          <div class="toolbar-label">Admin</div>
        </a>
        <!-- Profile -->
        <div *ngIf="user" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" class="toolbar-link" [matMenuTriggerFor]="profileMenu">
          <div *ngIf="(initials || photoUrl) || impersonating else accountCircle" [class]="'toolbar-profile-circle' + (impersonating ? '-orange' : '')">
            <img *ngIf="photoUrl else showInitials" [src]="photoUrl" class="toolbar-profile-circle-img" />
            <ng-template #showInitials><div>{{initials}}</div></ng-template>
          </div>
          <ng-template #accountCircle>
            <div class="toolbar-profile-icon-wrapper">
              <mat-icon class="toolbar-profile-icon">account_circle</mat-icon>
            </div>
          </ng-template>
        </div>
        <mat-menu #profileMenu>
          <!-- Actual Profile -->
          <div class="toolbar-profile">
            <div *ngIf="(initials || photoUrl) || impersonating else accountCircle" [class]="'toolbar-profile-circle-big' + (impersonating ? '-orange' : '')">
              <img *ngIf="photoUrl else showInitials" [src]="photoUrl" class="toolbar-profile-circle-big-img" />
              <ng-template #showInitials><div>{{initials}}</div></ng-template>
            </div>
            <ng-template #accountCircle>
              <div class="toolbar-profile-icon-wrapper">
                <mat-icon class="toolbar-profile-icon">account_circle</mat-icon>
              </div>
            </ng-template>
            <ng-container *ngIf="!impersonating">
              <input type="file" id="profile-upload" class="hide" (change)="uploadPhoto($event)">
              <label for="profile-upload" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" class="toolbar-profile-upload-button">
                <mat-icon>upload</mat-icon>
                Upload Photo
              </label>
            </ng-container>
            <div *ngIf="impersonating" class="orange">Impersonating</div>
            <div *ngIf="user.memberFullName">{{user.memberFullName}}</div>
            <div class="w-100" (click)="$event.stopPropagation()" *ngIf="realUser?.perms.is.Amp || user?.perms.is.Amp">
              <div class="toolbar-profile-small" *ngIf="user?.memberKey">ID: {{user.memberKey}}</div>
              <div class="toolbar-profile-small" *ngIf="user?.assistantToMember?.memberKey">Assisting: {{user?.assistantToMember.memberKey}}</div>
              <div class="toolbar-profile-small" *ngIf="user?.officeKey">Office: {{user.officeKey}}</div>
            </div>
            <div *ngIf="user.memberEmail" class="toolbar-profile-small">{{user.memberEmail}}</div>
            <div *ngIf="user.memberType">{{translatedMemberType}}</div>
            <div *ngIf="user?.assistantToMember?.memberFullName" class="toolbar-profile-small">Assisting {{user.assistantToMember.memberFullName}}</div>
            <div *ngIf="user.licensedAgent" class="toolbar-profile-small">
              <div class="toolbar-profile-small-icon-wrapper">
                <mat-icon color="accent" class="toolbar-profile-small-icon">verified</mat-icon>
              </div>
              &nbsp;Licensed Agent
            </div>
          </div>
          <!-- Options -->
          <div class="toolbar-profile-other">
            <!-- Dark Theme -->
            <div *ngIf="theme" class="toolbar-profile-other-option"
                  matRipple matRippleColor="rgba(255, 255, 255, 0.1)">
                <div class="row-between no-wrap w-100">
                  <div class="w-100" (click)="setDark(!theme?.dark)"><!-- This is a hack --></div>
                  <mat-slide-toggle [checked]="theme.dark" (change)="setDark($event.checked)"
                                    color="accent" ></mat-slide-toggle>
                  <div class="w-100" (click)="setDark(!theme?.dark)"><!-- This is a hack --></div>
                </div>
              <div class="toolbar-profile-other-text" (click)="setDark(!theme?.dark)">Dark Theme</div>
            </div>
            <!-- Log Out -->
            <div matRipple matRippleColor="rgba(255, 255, 255, 0.1)"
                  class="toolbar-profile-other-option" (click)="logout()">
              <mat-icon color="accent">logout</mat-icon>
              <div class="toolbar-profile-other-text">Log Out</div>
            </div>
          </div>
        </mat-menu>
      </div>
    </mat-toolbar>
  `,
  styleUrls: ['./_app.scss']
})
export class AppToolbar implements OnInit {

  //State
  public user: UserModel = null;
  public photoUrl: string = null;
  public realUser: UserModel = null;
  public initials: string = null;
  public creating: boolean = false;

  //Settings
  public homes = HomeArray;
  public homeDetails = HomeDetails;
  public home: HomeModel = null;
  public theme: ThemeModel = null;
  public urls: UrlsModel = null;
  public misc: MiscModel = null;

  //Dev Stuff
  public environment = environment;
  public devMode: boolean = environment.env === EnvironmentEnum.DEV;
  public impersonating: string = null;

  //Env Stuff
  public prodMode: boolean = environment.env == EnvironmentEnum.PROD;

  //Help Menu
  public supportUrl = environment.supportUrl;

  public get translatedMemberType(): string {
    return this.user?.memberRoles?.length ? this.user?.memberRoles?.map(role => role.roleName).join(', ').replace(/([A-Z][a-z])/g,' $1').replace(/(\d)/g,' $1') : null;
  }

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private notificationService: NotificationService,
    private propertyService: PropertyService,
    private compressionService: CompressionService,
    private clearService: ClearService
  ) { }

  ngOnInit() {
    this.load();
  }

  private load() {
    this.userService.getUserSub().subscribe(_user => {
      this.user = _user;
      this.photoUrl = this.user?.photoUrls?.length ? this.user?.getPhotoUrl('Thumbnail') : null;
      this.initials = this.user?.memberFirstName && this.user?.memberLastName ? this.user.memberFirstName[0] + this.user.memberLastName[0] : null;
      if (this.realUser?.perms.is.Amp || this.user?.perms.is.Amp) this.devMode = true;
      this.cdr.detectChanges();
    });
    this.location.onUrlChange(_location => {
      this.creating = _location.startsWith('/listing') || (_location.startsWith('/home') && this.home?.homePage !== HomeEnum.Table);
      this.cdr.detectChanges();
    });
    this.settingsService.getHome().subscribe(_home => {
      this.home = _home;
      this.creating = this.location.path().startsWith('/listing') || (this.location.path().startsWith('/home') && this.home?.homePage !== HomeEnum.Table);
      this.cdr.detectChanges();
    });
    this.settingsService.getTheme().subscribe(_theme => {
      this.theme = _theme;
      this.cdr.detectChanges();
    });
    this.settingsService.getUrls().subscribe(_urls => {
      this.urls = _urls;
      this.cdr.detectChanges();
    });
    this.settingsService.getMisc().subscribe(_misc => {
      this.misc = _misc;
      this.cdr.detectChanges();
    });
  }

  public logout() {
    this.dialog.open(AppDialog, {
      ...AppDialogConfig,
      data: {
        type: 'confirm',
        title: 'Log Out',
        message: 'Are you sure you want to log out?',
        confirm: 'Log Out'
      }
    }).afterClosed().subscribe(logout => {
      if (logout) this.router.navigate(['auth/logout']).catch(error => handleChunkLoadError(error, 'auth/logout'));
    });
  }

  public setDark(dark: boolean) {
    this.settingsService.setDark(dark, true);
  }

  public toTraining() {
    window.open('https://listing.uat.ampre.ca/', '_blank');
  }

  public uploadPhoto(event) {
    if (event.target.files.length) {
      this.compressionService.compress(event.target.files[0], {
        quality: 0.8,
        maxHeight: 1024,
        maxWidth: 1024
      }).then(file => {
        this.dialog.open(ImageEditDialog, {
          ...ImageEditDialogConfig,
          data: {
            fileName: file.name || event.target.files[0].name,
            fileType: file.type || event.target.files[0].type,
            blob: file || event.target.files[0],
            options: {
              maintainAspectRatio: true,
              aspectRatio: 1/1
            }
          }
        }).afterClosed().subscribe(uploadPhoto => {
          if (uploadPhoto) {
            this.userService.uploadPhoto(uploadPhoto);
          }
        });
      });
    }
  }

  /******************************************/
  /* Dev Options ****************************/
  /******************************************/
  public impersonate(key: string) {
    this.realUser = this.user?.clone();
    if (this.realUser?.perms.is.Amp) {
      this.authService.impersonate(this.user?.customerName, key).then(() => {
        this.impersonating = key;
        this.cdr.detectChanges();
      }).catch(() => {
        this.realUser = null;
        this.notificationService.error('Invalid key?');
      });
    }
  }
  public stopImpersonate() {
    this.realUser = null;
    this.impersonating = null;
    this.authService.stopImpersonate();
    this.cdr.detectChanges();
  }
  public setHomePage(homePage: HomeEnum) {
    this.settingsService.setHomePage(homePage);
  }
  public changeTheme(toolbar: string, primary: string, accent: string, warn: string) {
    this.settingsService.setToolbar(toolbar);
    this.settingsService.setPrimary(primary);
    this.settingsService.setAccent(accent);
    this.settingsService.setWarn(warn);
  }
  public changeBG(dark: boolean, color: string) {
    this.settingsService.setBG(dark, color);
  }
  public unauthorize() {
    this.userService.unauthorize();
  }
  public hideDevMenu() {
    this.devMode = false;
    this.cdr.detectChanges();
  }
  public autofill() {
    this.propertyService.autofill$.next();
  }
  public clearCache() {
    this.clearService.clearExceptUser();
  }
  public toggleConfirm() {
    environment.confirmAction = !environment.confirmAction;
  }
  public setLogLevel(level: 0 | 1 | 2 | 3) {
    environment['logLevel'] = level; //logLevel is private but this is js so who cares?
  }
}
