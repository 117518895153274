import { ChangeDetectorRef, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { MediaModel } from '../../../../../_model/media/media.model';
import { exists } from '../../../../../_helper/util.helper';


/**
 * A generic configurable dialog used to replace the browser's default alert with a prettier one
 */
@Component({
  template: `
    <div class="image-edit-container">
      <div class="image-edit-toolbar-top">
        <div class="image-edit-name w-33">
          Edit {{data.fileName}}
        </div>
        <div class="image-edit-tools">
          <!-- Crop Ratio -->
          <ng-container *ngIf="!maintainAspectRatio">
            <div class="image-edit-button" matTooltip="Crop" [matMenuTriggerFor]="menu">
              <mat-icon class="image-edit-button-icon">crop</mat-icon>
              {{aspectRatioText}}
              <mat-icon class="image-edit-button-icon">arrow_drop_down</mat-icon>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="setAspectRatio(null)">Free</button>
              <button mat-menu-item (click)="setAspectRatio(1, 1)">Square</button>
              <button mat-menu-item (click)="setAspectRatio(16, 9)">16:9</button>
              <button mat-menu-item (click)="setAspectRatio(16, 10)">16:10</button>
              <button mat-menu-item (click)="setAspectRatio(4, 3)">4:3</button>
              <button mat-menu-item (click)="setAspectRatio(9, 16)">9:16</button>
              <button mat-menu-item (click)="setAspectRatio(10, 16)">10:16</button>
            </mat-menu>
          </ng-container>

          <!-- Rotate +/- 90* -->
          <!-- The rotations are done at +/- 45*. Seems unintuitive because it is, but I guess that's a bug in the dependency -->
          <mat-icon class="image-edit-icon-button" matTooltip="Rotate Left" (click)="rotate(-45)">undo</mat-icon>
          <mat-icon class="image-edit-icon-button" matTooltip="Rotate Right" (click)="rotate(45)">redo</mat-icon>
          <!-- Flip V/H -->
          <mat-icon class="image-edit-icon-button" matTooltip="Flip Horizontal" (click)="flip('H')">flip</mat-icon>
          <mat-icon class="image-edit-icon-button" matTooltip="Flip Vertical" (click)="flip('V')" [style.transform]="'rotate(90deg)'">flip</mat-icon>
        </div>
        <div class="row-end w-33">
          <mat-icon class="image-edit-close" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" (click)="close(false)">
            close
          </mat-icon>
        </div>
      </div>
      <div class="image-edit-wrapper">
        <image-cropper #cropper
            [display]="loaded"
            [imageFile]="image"
            [aspectRatio]="aspectRatio"
            [maintainAspectRatio]="maintainAspectRatio"
            [roundCropper]="roundCropper"
            [imageQuality]="imageQuality"
            [backgroundColor]="backgroundColor"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </div>
      <div class="image-edit-toolbar-bottom">
        <div class="image-edit-button" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" (click)="close(false)">
          <mat-icon class="image-edit-button-icon">cancel</mat-icon><span class="hide-tablet">&nbsp;Cancel</span>
        </div>
        <div class="image-edit-button" matRipple matRippleColor="rgba(255, 255, 255, 0.1)" (click)="close(true)">
          <mat-icon class="image-edit-button-icon">save</mat-icon><span class="hide-tablet">&nbsp;Done</span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./_file-dialog.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageEditDialog {

  //State
  public edited: string = null;
  public image: File = null;
  public loaded: boolean = false;
  public aspectRatioText: string = '';

  //Cropper config
  @ViewChild('cropper') cropper: ImageCropperComponent = null;
  // https://www.npmjs.com/package/ngx-image-cropper
  public maintainAspectRatio: boolean = false;
  public aspectRatio: number = null;
  public roundCropper: boolean = false;
  public imageQuality: number = 100;
  public backgroundColor: string = 'rgba(0,0,0,0)';
  public canvasRotation: number = 0;
  public transform: ImageTransform = {};
  //this ^^^ is vvv
  // scale?: number;
  // rotate?: number;
  // flipH?: boolean;
  // flipV?: boolean;
  // translateH?: number;
  // translateV?: number;
  // translateUnit?: '%' | 'px';

  constructor(
    private self: MatDialogRef<ImageEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // file: MediaModel,
      fileName: string,
      fileType: string,
      blob: File,
      options?: {
        maintainAspectRatio?: boolean
        aspectRatio?: number
        roundCropper?: boolean
        imageQuality?: number
        backgroundColor?: string
        canvasRotation?: number
        transform?: ImageTransform
      }
    },
    private cdr: ChangeDetectorRef
  ) {
    this.image = this.data.blob;
    this.setOptions();
  }

  private setOptions() {
    if (this.data.options) {
      if (exists(this.data.options.maintainAspectRatio)) this.maintainAspectRatio = this.data.options.maintainAspectRatio;
      if (exists(this.data.options.aspectRatio)) this.aspectRatio = this.data.options.aspectRatio;
      if (exists(this.data.options.roundCropper)) this.roundCropper = this.data.options.roundCropper;
      if (exists(this.data.options.imageQuality)) this.imageQuality = this.data.options.imageQuality;
      if (this.data.options.backgroundColor) this.backgroundColor = this.data.options.backgroundColor;
      if (exists(this.data.options.canvasRotation)) this.canvasRotation = this.data.options.canvasRotation;
      if (this.data.options.transform) this.transform = this.data.options.transform;
    }
  }

  /********************************
   * Cropper Stuff ****************
   ********************************/

  public imageCropped(event: ImageCroppedEvent) {
    this.edited = event.base64;
  }

  public imageLoaded(_image: LoadedImage) {
    this.loaded = true;
    this.setOptions();
  }

  public cropperReady() {
    this.loaded = true;
    this.setOptions();
  }

  public loadImageFailed() {
    //TODO: idk
  }

  /********************************
   * Component Stuff***************
   ********************************/

  public setAspectRatio(width?: number, height?: number) {
    this.maintainAspectRatio = (width !== null);
    this.aspectRatioText = width === null ? '' :`${width}:${height}`
    this.aspectRatio = width / height;
    this.cropper?.resetCropperPosition();
    this.cdr.detectChanges();
  }

  public rotate(degrees: number) {
    let rotation = this.canvasRotation + degrees;
    if (rotation >= 360) rotation = 0;
    this.canvasRotation = rotation;
  }

  public flip(direction: 'H' | 'V') {
    this.transform['flip' + direction] = !this.transform?.['flip' + direction];
    this.transform = {...this.transform}; //necessary for Change Detection
  }

  public async close(save: boolean) {
    if (save) { //convert back to file from base64 string
      let res: Response = await fetch(this.edited);
      let blob: Blob = await res.blob();
      let file = new File([blob], (this.data.fileName), { type: blob.type || this.data.fileType });
      this.self.close(file);
    } else {
      this.self.close();
    }
  }
}

export const ImageEditDialogConfig = {
  width: '100vw',
  height: '100vh',
  maxWidth: '100vw',
  maxHeight: '100vh',
  panelClass: 'fullscreen-dialog'
}
