import { exists } from "../../../_helper/util.helper";

export class EditModel {

  public showMlsNumber: boolean = true;
  public mlsNumberLabel: string = "MLS#";

  public showAddress: boolean = true;
  public addressLabel: string = "Address";

  public showPropertyType: boolean = true;
  public propertyTypeLabel: string = "Class";

  public showMlsStatus: boolean = true;
  public mlsStatusLabel: string = "Status";

  public showAgent: boolean = true;
  public agentLabel: string = "Agent";

  public showPrice: boolean = true;
  public priceLabel: string = "Price";

  public showCreatedDate: boolean = true;
  public createdDateLabel: string = "Created";

  public showModifiedDate: boolean = false;
  public modifiedDateLabel: string = "Edited";

  constructor(model?: Partial<EditModel>) {
    if (exists(model?.showMlsNumber)) this.showMlsNumber = model.showMlsNumber;
    if (model?.mlsNumberLabel) this.mlsNumberLabel = model.mlsNumberLabel;
    if (exists(model?.showAddress)) this.showAddress = model.showAddress;
    if (model?.addressLabel) this.addressLabel = model.addressLabel;
    if (exists(model?.showPropertyType)) this.showPropertyType = model.showPropertyType;
    if (model?.propertyTypeLabel) this.propertyTypeLabel = model.propertyTypeLabel;
    if (exists(model?.showMlsStatus)) this.showMlsStatus = model.showMlsStatus;
    if (model?.mlsStatusLabel) this.mlsStatusLabel = model.mlsStatusLabel;
    if (exists(model?.showAgent)) this.showAgent = model.showAgent;
    if (model?.agentLabel) this.agentLabel = model.agentLabel;
    if (exists(model?.showPrice)) this.showPrice = model.showPrice;
    if (model?.priceLabel) this.priceLabel = model.priceLabel;
    if (exists(model?.showCreatedDate)) this.showCreatedDate = model.showCreatedDate;
    if (model?.createdDateLabel) this.createdDateLabel = model.createdDateLabel;
    if (exists(model?.showModifiedDate)) this.showModifiedDate = model.showModifiedDate;
    if (model?.modifiedDateLabel) this.modifiedDateLabel = model.modifiedDateLabel;

  }
}
