import { alwaysDeleteElement, ElementModel } from "./element.model";
import { InputDetails, InputEnum } from "../../_enum/form/input.enum";
import { PatternEnum } from "../../_enum/form/pattern.enum";
import { DataTypeEnum } from "../../_enum/form/data-type.enum";
import { ClassEnum } from "../../_enum/form/class.enum";
import { ColumnEnum, ColumnArray } from "../../_enum/form/column.enum";
import { LookupModel } from "../metadata/lookup.model";
import { KeyValueInterface } from "./interface/key-value.interface";

/** Properties to always delete from an input when compacting */
export const alwaysDeleteInput = [ ...alwaysDeleteElement, 'filteredLookups', 'filteredValues' ];

/**
 * A model that attempts to replicate the state of the majority of html input elements
 */
export class InputModel extends ElementModel {

  //state
  public input: InputEnum = null;
  public lookup: string = null;
  public placeholder: string = null;
  public hint: string = null;
  public value: any = null;
  public values: KeyValueInterface[] = [];

  //presently only used for file and image inputs
  public columns: ColumnEnum = null;

  //validation
  public min: number = 0;
  public max: number = null;
  public pattern: PatternEnum = null;
  public dataType: DataTypeEnum = null;
  public block: string[] = [];

  //Runtime only
  public filteredLookups: LookupModel[] = [];
  public filteredValues: any[] = [];

  constructor(model?: Partial<InputModel | any>) {
    super();
    this.overwrite(model);
    this.class = ClassEnum.Input;
  }

  public overwrite(model: Partial<any>, ...exclude: string[]) {
    super.overwrite(model, 'class', ...alwaysDeleteInput, ...exclude);
    if (this.input) if (this.placeholder === InputDetails[this.input]?.desc || '') this.placeholder = null;
    if (!this.width) this.width = this.getInputWidth(this.input);
    if (!this.columns) this.columns = this.getInputColumns(this.input);
    if (this.dataType === DataTypeEnum.boolean) this.values = [ { key: true, value: 'Yes' }, { key: false, value: 'No' } ];
  }

  private getInputWidth(input: InputEnum) {
    switch (input) {
      //some inputs take up the full width by default
      case InputEnum.TextArea:
      //SAVED: we don't need this at present
      // case InputEnum.MultiText:
      case InputEnum.MultiSelect:
      case InputEnum.ButtonToggle:
      case InputEnum.File:
      case InputEnum.Image:
      case InputEnum.Map:
      case InputEnum.SearchOfficeInfo:
      case InputEnum.SearchAgentOffice:
      case InputEnum.SearchBuyerAgentOffice:
        return ColumnArray[ColumnArray.length - 1];
      //default is one
      default:
        return ColumnEnum.One;
    }
  }

  private getInputColumns(input: InputEnum) {
    switch (input) {
      case InputEnum.File: return ColumnEnum.Four;
      case InputEnum.Image: return ColumnEnum.Three;
      default: return null;
    }
  }
}
