import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { decode, isExpired } from "../_helper/jwt.helper";
import { SettingsConfigModel } from "../_model/form/configs/settings-config.model";
import { ClaimsInterface } from "../_model/member/claims.interface";
import { UserModel } from "../_model/member/user.model";
import { IDBTable, IndexedDBService } from "./indexed-db.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private table = IDBTable.User as const;
  private url = environment.apiUrl + 'members/';

  //User
  private user: UserModel = null;
  private impersonateUser: UserModel = null;
  private user$ = new BehaviorSubject<UserModel>(this.user);
  private token: string = null;
  private impersonateToken: string = null;
  private token$ = new BehaviorSubject<string>(this.token);

  constructor(
    private http: HttpClient,
    private idb: IndexedDBService
  ) { }

  public async load() {
    //preload user
    if (!this.user) {
      await this.idb.get(this.table, 'user').then(_user => {
        if (_user) {
          this.user = new UserModel(_user);
          this.user$.next(this.user);
        }
      }, error => {
        environment.error(error);
      });
    }
    //preload token
    if (!this.token) {
      await this.idb.get(this.table, 'token').then(_token => {
        if (_token && !isExpired(_token)) {
          this.token = _token;
          this.token$.next(this.token);
        }
      }, error => {
        environment.error(error);
      });
    }
  }

  /**
   * Gets the stored user asyncronously
   */
  public getUserAsync(): Promise<UserModel> {
    return new Promise(async resolve => {
      if (!this.user) await this.load();
      return resolve(this.user);
    });
  }

  /**
   * Gets the stored user as an observable
   */
  public getUserSub(): Observable<UserModel> {
    if (!this.user) this.load();
    return this.user$.asObservable();
  }

  /**
   * Gets the stored token asyncronously
   */
  public getTokenAsync(): Promise<string> {
    return new Promise(async resolve => {
      if (!this.token) await this.load();
      return resolve(this.token);
    });
  }

  /**
   * Gets the stored token as an observable
   */
  public getTokenSub(): Observable<string> {
    if (!this.token) this.load();
    return this.token$.asObservable();
  }

  /**
   * Gets the user's locally stored form settings configuration
   */
  public getSettingsConfig(): Promise<SettingsConfigModel> {
    return this.idb.get(this.table, 'form_settings');
  }

  /**
   * Saves the user's locally stored form settings configuration
   */
  public saveSettingsConfig(config: SettingsConfigModel): Promise<void> {
    return this.idb.save(this.table, 'form_settings', config);
  }

  /**
   * Checks if the user has an un-expired token
   */
  public isUnauthorized(): boolean | Promise<boolean> {
    if (!this.token) return false;
    else return isExpired(this.token);
  }

  /**
   * Sets the token and gets the user from the server using the claims in the token, stores the user.
   * Impersonate can be passed in if the user is not you.
   */
  public set(token: string, impersonate?: boolean): Promise<void> {
    if (impersonate) this.impersonateToken = token;
    else this.token = token;
    this.token$.next(this.impersonateToken || this.token);
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + (this.impersonateToken || this.token));
    let claims: ClaimsInterface = decode(token);
    return new Promise((resolve, reject) => {
      this.http.get(this.url + claims.sub.key, { headers: headers }).subscribe(async _user => {
        if (impersonate) {
          this.impersonateUser = new UserModel(_user);
          environment.warn('Impersonating', this.impersonateUser);
        } else this.user = new UserModel(_user);
        this.user$.next(this.impersonateUser || this.user);
        await this.idb.save(this.table, 'token', this.token).catch(error => {
          reject(error);
        });
        await this.idb.save(this.table, 'user', this.user).catch(error => {
          reject(error);
        });
        resolve();
      }, error => {
        if (!impersonate) this.clear();
        reject(error);
      })
    });
  }

  /**
   * Clears the user data from both the UserService and the IndexedDB
   */
  public clear() {
    //clean user vars
    this.user = null;
    this.token = null;
    //clean user storage
    this.idb.delete(this.table, 'user').catch(error => {
      environment.error(error);
    });
    this.idb.delete(this.table, 'token').catch(error => {
      environment.error(error);
    });
    //clean user subs
    this.user$.next(null);
    this.token$.next(null);
  }

  /**
   * Literally just deletes the user's token from memory/storage.
   * Really just here to make it easier to test http failure handling
   */
  public unauthorize() {
    if (!environment.production) {
      this.token = null;
      this.impersonateToken = null;
      this.idb.delete(this.table, 'token').catch(error => {
        environment.error(error);
      });
      this.token$.next(null);
    }
  }

  /**
   * Gets a valid token for a third-party website.
   */
  public get3rdPartyToken(resource: string): Promise<string> {
    let _url = environment.apiUrl + 'third-party-token/' + resource;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
    return this.http.get(_url, { responseType: 'text', headers: headers }).toPromise();
  }

  /**
   * Stops impersonating another user.
   */
  public stopImpersonate() {
    this.impersonateToken = null;
    this.impersonateUser = null;
    this.token$.next(this.token);
    this.user$.next(this.user);
  }

  public async uploadPhoto(file: File) {

    let url = environment.apiUrl + 'media/members/' + this.user.memberKey  + '/image/upload';

    let headers = new HttpHeaders();
    if (this.token) {
      headers = headers.append('Authorization', 'Bearer ' + this.token);
    }

    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(url, formData, { headers: headers }).toPromise().then(() => {
      this.set(this.impersonateToken || this.token, this.impersonateToken ? true : false);
    });
  }
}
