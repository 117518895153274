import { TabEnum } from "../../../_enum/settings/tab.enum";
import { EditModel } from "./edit.model";
import { MapModel } from "./map.model";
import { PublicRecordModel } from "./public-record-model";
import { TableModel } from "./table.model";

export class TabModel {

  public label: string = null;
  public name: string = null;
  public type: TabEnum = null;
  public table: TableModel = null;
  public map: MapModel = null;
  public edit: EditModel = null;
  public publicRecord: PublicRecordModel = null;

  constructor(model?: Partial<TabModel>) {
    if (model?.label) this.label = model.label;
    if (model?.name) this.name = model.name;
    if (model?.type) this.type = model.type;
    switch (this.type) {
      case TabEnum.Edit:
        this.edit = new EditModel(model.edit);
        break;
      case TabEnum.Table:
        this.table = new TableModel(model.table);
        break;
      case TabEnum.Map:
        this.map = new MapModel(model.map);
        break;
      case TabEnum.CreatePublicRecord:
        this.publicRecord = new PublicRecordModel(model.publicRecord);
        this.map = new MapModel(model.map);
        break;
      default: break;
    }
    if (model?.table) this.table = new TableModel(model.table);
    if (model?.map) this.map = new MapModel(model.map);
    if (model?.publicRecord) this.publicRecord = new PublicRecordModel(model.publicRecord);
    if (model?.edit) this.edit = new EditModel(model.edit);
  }
}
