import { environment } from "../../../environments/environment";
import { EnvironmentEnum } from "../../../environments/environment.base";
import { exists } from "../../_helper/util.helper";

export class MiscModel {

  public showOrangeToolbar: boolean = false;
  public orangeToolbarText: string = null;
  public orangeToolbarSubtext: string = null;

  constructor(model?: Partial<MiscModel>) {
    if (exists(model?.showOrangeToolbar)) this.showOrangeToolbar = model.showOrangeToolbar;
    else if (environment.env === EnvironmentEnum.UAT) this.showOrangeToolbar = true;

    if (exists(model?.orangeToolbarText)) this.orangeToolbarText = model.orangeToolbarText;
    else if (environment.env === EnvironmentEnum.UAT) this.orangeToolbarText = `New Add/Edit Training Mode`;

    if (exists(model?.orangeToolbarSubtext)) this.orangeToolbarSubtext = model.orangeToolbarSubtext;
    else if (environment.env === EnvironmentEnum.UAT) this.orangeToolbarSubtext = `New practice listings entered can be viewed in Stratus Training Mode`;
  }

}
