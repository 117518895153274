import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Allows control over the CSS display property of an HTML element with a simple boolean.
 */
@Directive({
  selector: '[display]'
})
export class DisplayDirective {

  @Input() set display(display: boolean) {
    this.el.nativeElement.style.display = display ? 'initial' : 'none';
  }

  constructor(
    private el: ElementRef
  ) { }
}
